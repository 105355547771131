<template>

    <div class="badge" :class="dynamicClass" >
        <slot></slot>
    </div>
</template>

<script>
import {computed} from 'vue';
export default {
    props: {
        theme: {
            type: String,
            default: 'lead',
        },
        size: {
            type: String,
            default: 'm'
        }
    },
    data: function () {

        return {
            defaultColorMap: {
                'color': 'c-bg',
                'border-color': 'c-' + this.theme,
                'background-color': 'c-' + this.theme
            },
            themeColorMap: {
                'bg' : {
                    'color': 'c-inverse',
                    'border-color': 'c-inverse',
                }
            }
        };
    },
    computed: {
        colorTemplate () {
            let override = {};
            if (this.themeColorMap.hasOwnProperty(this.theme)) {

                override = {...this.themeColorMap[this.theme]};

            }
            return {...this.defaultColorMap, ...override}
        },
        computedStyle () {
            let style = {};
            for( const [key, value] of Object.entries(this.colorTemplate)) {
                style[key] = `var(--${value})`
            }
            return style
        },
        dynamicClass() {
            return [
                'badge--'+this.size,
                'badge--'+this.theme
            ];
        }
    }

}
</script>

<style scoped lang="scss">

</style>
